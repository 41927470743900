import React, { useRef } from 'react'
import { Search } from '@tamagui/lucide-icons'
import { Input, type InputProps, XStack } from 'tamagui'

export const SearchInput: React.FC<InputProps> = (props) => {
  const inputRef = useRef<Input>(null)

  return (
    <XStack
      borderWidth={1}
      borderColor="$neutral500"
      borderRadius={11}
      paddingVertical={8}
      paddingHorizontal={10}
      alignItems="center"
      gap={5}
      onPress={(): void => inputRef.current?.focus()}
    >
      <Search color="$neutral700" />
      <Input
        {...props}
        ref={inputRef}
        unstyled
        fontSize={14}
        color="$Gray1"
        fontFamily="$latoFont"
        focusVisibleStyle={{
          outlineStyle: 'none',
        }}
      />
    </XStack>
  )
}
