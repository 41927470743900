import type { Brand } from '@centrito/db/prisma/generated/zod'
import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'
import type { GetProducts } from '@centrito/api/shared/catalog'
import { trpc } from '@centrito/app/api/trpc'

interface UseRelevantFilterDataResponse {
  relevantMerchants: { merchantName: string; merchantId: string }[] | undefined
  relevantBrands: Brand[] | undefined
  relevantBrandsIsFetched: boolean
  relevantPriceRange: { low: number; high: number }
  relevantCategories: CategoryPublic[] | undefined
  relevantCategoriesIsFetched: boolean
  relevantColors: { hex: string; key: string; name: string }[] | undefined
  relevantSizes: string[] | undefined
  relevantFiltersIsFetched: boolean
}

export const useRelevantFilterData = (
  filters: GetProducts.Filters,
): UseRelevantFilterDataResponse => {
  const relevantFiltersQuerySettings = {
    enabled: Object.keys(filters).length !== 0,
  }

  const { data: relevantBrands, isFetched: relevantBrandsIsFetched } =
    trpc.catalog.filters.getBrands.useQuery({ filters }, relevantFiltersQuerySettings)

  const { data: relevantCategories, isFetched: relevantCategoriesIsFetched } =
    trpc.catalog.filters.getCategories.useQuery({ filters }, relevantFiltersQuerySettings)

  const { data: relevantFilters, isFetched: relevantFiltersIsFetched } =
    trpc.catalog.filters.getFilters.useQuery({
      filters,
    })

  return {
    relevantMerchants: relevantFilters?.merchants,
    relevantBrands,
    relevantBrandsIsFetched,
    relevantPriceRange: relevantFilters?.pricing ?? { low: 2000, high: 100000 },
    relevantCategories,
    relevantCategoriesIsFetched,
    relevantColors: relevantFilters?.colors,
    relevantSizes: relevantFilters?.sizes,
    relevantFiltersIsFetched,
  }
}
