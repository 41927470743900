import type { Dispatch, SetStateAction } from 'react'
import getInitialOptions from './getInitialOptions'
import type { FilterOverlay } from './types'

const useResetOptions = (
  setOptions: Dispatch<SetStateAction<FilterOverlay.Options>>,
): { resetOptions: FilterOverlay.ResetOptionsFn } => {
  const resetOptions: FilterOverlay.ResetOptionsFn = (filters) => {
    const newOptions = getInitialOptions(filters, [], [], [], [], [], { low: 2000, high: 150000 })
    setOptions(newOptions)
  }

  return { resetOptions }
}

export default useResetOptions
