import React, { useContext, useState } from 'react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import PATHS from '@centrito/common/paths'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import SeeMoreOrLessButton from '@centrito/app/components/Feed/components/SeeMoreOrLessButton'
import useWindowDimensions from '@centrito/app/utils/hooks/useWindowDimensions'
import { XStack, YStack } from '@centrito/ui/src'
import { CircleFeedButton } from '@centrito/ui/src/components/button/CircleFeedButton'

export const CategoriesSeeMoreDisplay: React.FC<object> = () => {
  const { categories, isBrandFilter, brandId } = useContext(FilterOverlayContext)
  const [isShowingAll, setIsShowingAll] = useState<boolean>(false)
  const { width: _windowWidth } = useWindowDimensions()
  const displayItems = Math.round(_windowWidth / 85)
  const displayedCategoriesCount = displayItems * 2 - 1
  return (
    <YStack alignItems="center" alignContent="center" justifyContent="center">
      <XStack
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        maxWidth={displayItems * 80}
      >
        {categories
          .slice(0, isShowingAll ? categories.length : displayedCategoriesCount)
          .map((category) => (
            <CircleFeedButton
              key={`category-browser--${category.nodes}`}
              href={{
                pathname: isBrandFilter
                  ? PATHS.Products.ListByBrand
                  : PATHS.Products.ListByCategoryPrefix,
                query: {
                  categoryNodesPrefix: category.nodes,
                  ...(!isNil(brandId) && !isEmpty(brandId) ? { brandId } : {}),
                },
              }}
              imageSrc={category.assets.tab || ''}
              imageAlt={category.nodes}
              label={category.nodesNamed.split('-').pop() || ''}
            />
          ))}
        {categories.length > displayedCategoriesCount && (
          <YStack onPress={(): void => setIsShowingAll(!isShowingAll)} minWidth={80}>
            <SeeMoreOrLessButton isShowingAll={isShowingAll} width={80} />
          </YStack>
        )}
      </XStack>
    </YStack>
  )
}
