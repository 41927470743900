import React, { useContext } from 'react'
import { X } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import { FILTER_OVERLAY_OPTION_GROUP } from '@centrito/app/components/Feed/FilterBar/Overlay/context/constants'
import { Stack } from '@centrito/ui/src'
import { HighlightBoldText } from '@centrito/ui/src/components/text/new'

export const OverlayHeader: React.FC = () => {
  const { currentFilterDrawer, setIsVisibleOverlay } = useContext(FilterOverlayContext)

  return (
    <Stack
      flexDirection="row"
      paddingTop={10}
      alignItems="center"
      paddingHorizontal={15}
      marginBottom={20}
    >
      <HighlightBoldText color="$pureBlack">
        {isNil(currentFilterDrawer)
          ? 'Todos los filtros'
          : `Filtrar por ${FILTER_OVERLAY_OPTION_GROUP[
              currentFilterDrawer
            ]?.optionGroupName.toLowerCase()}`}
      </HighlightBoldText>
      <Stack marginLeft="auto" onPress={(): void => setIsVisibleOverlay(false)}>
        <X size={24} />
      </Stack>
    </Stack>
  )
}
