import React, { useContext } from 'react'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import type { FilterOverlay } from '@centrito/app/components/Feed/FilterBar/Overlay/context/types'
import { Spacer, XStack } from '@centrito/ui/src'
import { SimpleCheckbox } from '@centrito/ui/src/components/SimpleCheckbox'
import { DefaultTextTertiary } from '@centrito/ui/src/components/text'

interface CategoryOptionItemProps {
  optionItem: FilterOverlay.OptionItem
}

export const CategoryOptionItem: React.FC<CategoryOptionItemProps> = ({ optionItem }) => {
  const { changeOptionItemIsChecked } = useContext(FilterOverlayContext)
  const { name, icon, isChecked } = optionItem

  return (
    <XStack
      onPress={(): void => changeOptionItemIsChecked(optionItem)}
      width="100%"
      alignItems="center"
    >
      {icon}
      <DefaultTextTertiary
        textAlign="left"
        maxWidth={'60%'}
        numberOfLines={2}
        marginLeft="$4"
        alignSelf="center"
      >
        {name}
      </DefaultTextTertiary>
      <Spacer flexGrow={1} />
      <XStack padding="$3.5">
        <SimpleCheckbox isChecked={isChecked} />
      </XStack>
    </XStack>
  )
}
