import React, { useContext } from 'react'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import { formatPrice } from '@centrito/app/utils/products'
import { Slider, XStack, YStack } from '@centrito/ui/src'
import { ContentStandardText } from '@centrito/ui/src/components/text/new'

export const PriceSlider: React.FC = () => {
  const { priceRange, setPriceRange, priceRangeBoundaries } = useContext(FilterOverlayContext)

  return (
    <YStack flex={1} paddingHorizontal={28.5} paddingVertical={20} gap={10}>
      <Slider
        max={priceRangeBoundaries.high}
        min={priceRangeBoundaries.low}
        value={priceRange}
        onValueChange={setPriceRange}
        step={1000}
      >
        <Slider.Track backgroundColor="$softGray">
          <Slider.TrackActive backgroundColor="$primary1000" />
        </Slider.Track>
        <Slider.Thumb
          index={0}
          backgroundColor="$primary1000"
          borderColor="#primary1000"
          circular
          size={26}
          hoverStyle={{
            backgroundColor: '$primary1000',
            borderColor: '$primary1000',
          }}
          pressStyle={{
            backgroundColor: '$primary1000',
            borderColor: '$primary1000',
          }}
          focusStyle={{
            backgroundColor: '$primary1000',
            borderColor: '$primary1000',
          }}
          focusVisibleStyle={{
            backgroundColor: '$primary1000',
            borderColor: '$primary1000',
            outlineStyle: 'none',
          }}
        />
        <Slider.Thumb
          index={1}
          backgroundColor="$primary1000"
          borderColor="$primary1000"
          circular
          size={26}
          hoverStyle={{
            backgroundColor: '$primary1000',
            borderColor: '$primary1000',
          }}
          pressStyle={{
            backgroundColor: '$primary1000',
            borderColor: '$primary1000',
          }}
          focusStyle={{
            backgroundColor: '$primary1000',
            borderColor: '$primary1000',
          }}
          focusVisibleStyle={{
            backgroundColor: '$primary1000',
            borderColor: '$primary1000',
            outlineStyle: 'none',
          }}
        />
      </Slider>
      <XStack justifyContent="space-between">
        <ContentStandardText fontSize={16}>{formatPrice(priceRange[0])}</ContentStandardText>
        <ContentStandardText fontSize={16}>{formatPrice(priceRange[1])}</ContentStandardText>
      </XStack>
    </YStack>
  )
}
